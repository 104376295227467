@import 'variables.scss';

@font-face {
  font-family: "FabricMDL2Icons";
  src: url("./fonts/fabric-icons-bd197133.woff") format("woff");
}

.app {
  text-align: center;
}
