@import '../../../variables.scss';
.resultPersonGraph {
	background-color: transparent;
}
.list {
	list-style: none;
	margin: 0;
	padding: 0;
	& > li {
		margin: 24px 0 0 0;
	}
}
h6 {
	font-size: 14px;
	font-weight: 600;
	margin: 8px 0;
	display: flex;
	flex-direction: row;
	gap: 6px;
	align-items: center;
	.detailsButton {
		:global {
			.ms-Icon {
				color: $black_43 !important;
			}
		}
	}
}

.graphs {
	font-size: 12px;
	font-weight: 600;
}
.graph {
	display: grid;
	grid-template-columns: 60px 1fr;
	grid-column-gap: 12px;
	.label {
		justify-self: end;
	}
}
.option {
	align-items: center;
	display: flex;
	justify-content: flex-start;
	column-gap: 8px;
}
.itemAppear {
	.bar {
		width: 0 !important;
	}
}
.bar {
	border-radius: 5px;
	display: block;
	height: 10px;
	padding: 0 8px;
	transition: width ease-in-out 1s;
	width: 0;
	&.empty {
		background-color: $grey_d1;
	}
}
.yes .bar:not(.empty) {
	background-color: $blue_18;
}
.no .bar:not(.empty) {
	background-color: $red_c7;
}
.abstent .bar:not(.empty) {
	background-color: $neutralDark;
}
