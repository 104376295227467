@import '../../../variables.scss';

.logo {
	color: $black_43;
	display: flex;
	font-size: 14px;
	text-decoration: none !important;
}
.small {
	align-items: center;
	img {
		width: 42px;
		margin-right: 8px;
	}
}
.big {
	img {
		width: 147px;
	}
	span {
		display: none;
	}
}
