@import '../variables.scss';

.img {
	image-rendering: optimizeSpeed;
}

.pageContainer {
	background: transparent
		linear-gradient(205deg, $desktop-backgroundcolor 0%, $primary-base-color 48%, $primary-color 100%) 0% 0% no-repeat
		padding-box;
	box-sizing: border-box;
	text-align: left;
	overflow: clip;
	transform: translateZ(0);
	width: 100%;

	h1 {
		color: $white;
		font-size: 16px;
		font-weight: normal;
		line-height: 20px;
	}

	.headerContainer {
		align-items: center;
		box-sizing: border-box;
		display: flex;
		height: 88px;
		line-height: 100%;
		justify-content: space-between;
		padding: 32px 16px;

		.votrLogo {
			height: 44px;
		}

		.noLogoMenuSpan {
			align-self: center;
		}
	}

	.logoWrapperDiv {
		background-color: $white;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 174px;
		z-index: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 24px;
		img {
			max-width: 64%;
			max-height: 100px;
		}
		&.noLogo {
			background-color: transparent;
			height: 60px;
		}
	}

	.headerInnerContainer {
		display: flex;
		height: 100%;
		justify-content: space-between;
		align-items: center;
		width: 100vw;
		@include for-desktop {
			margin: auto;
			width: 1008px;
		}
		h1 {
			@include for-desktop {
				padding-left: 16px;
			}
		}
	}

	.menuButton {
		height: 22px;
		width: 22px;
		i {
			-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
			color: $white;
			cursor: pointer;
			font-size: 22px;
		}
	}

	.startpage {
		background: transparent
			linear-gradient(205deg, $desktop-backgroundcolor 0%, $primary-base-color 48%, $primary-color 100%) 0% 0% no-repeat
			padding-box;
		overflow: hidden;
		.headerContainer {
			// animation-name: startpageHeaderFadeIn;
			// animation-duration: 1s;
			// animation-timing-function: ease-in;
			background-color: transparent;
			justify-content: space-around;
			height: 88px;
			line-height: 88px;
			transform: translateY(0px);
		}
		.contentContainer {
			// animation-name: startpageContentFadeIn;
			// animation-duration: 1.8s;
			// animation-timing-function: ease-in;
			display: flex;
			flex-direction: column;
			height: calc(100vh - 88px);
			height: calc((var(--vh, 1vh) * 100) - 88px);
			justify-content: center;
			overflow: hidden;
			min-height: 300px;
		}
		&.fadeOut {
			.contentContainer {
				// animation-name: startpageContentFadeOut;
				// animation-duration: 1s;
				height: 0px;
				max-height: 0px;
				overflow: hidden;
				opacity: 0;
				transition: opacity 0.25s linear;
			}
			.contentExitContainer {
				// animation-name: startpageContentFadeIn;
				// animation-duration: 1s;
				background-color: $backgroundcolor;
				height: calc(100vh - 88px);
				height: calc((var(--vh, 1vh) * 100) - 88px);
				min-height: 280px;
				overflow: hidden;
			}
			.headerContainer {
				// animation-name: startpageHeaderFadeOut;
				// animation-duration: 1.25s;
				transform: translateY(-88px);
			}
		}
	}

	.loginPage {
		.headerContainer {
			align-items: center;
			height: 88px;
			line-height: 100%;
			transform: translateZ(0);
			overflow: hidden;
			width: 100vw;
			z-index: 10;
			.headerInnerContainer {
				// animation-name: pinHeaderFadeIn;
				// animation-duration: 1s;
				transform: translateZ(0);
				i {
					line-height: 22px;
				}
			}
		}
		.contentContainer {
			background-color: $backgroundcolor;
			display: flex;
			flex-direction: column;
			height: calc(100vh - 88px);
			height: calc((var(--vh, 1vh) * 100) - 88px);
			min-height: 300px;
		}
		&.fadeOut {
			.contentContainer > div {
				opacity: 0;
				transition: opacity 0.25s linear;
			}
		}

		:global {
			.ms-TextField-errorMessage {
				background-color: transparent;
			}
		}
	}

	.menuPage {
		background-color: $white;
		color: $text-default-color;
		overflow: hidden;
		position: fixed;
		right: 0;
		top: 0;
		bottom: 0;
		left: 100vw;
		width: 100vw;
		z-index: 10;

		&[class~='sidePanelOpen-enter'],
		&[class~='sidePanelOpen-exit'] {
			transition: left 0.25s linear;
		}

		@include for-desktop {
			width: 430px;
		}

		&.open {
			left: 0;

			@include for-desktop {
				left: calc(100vw - 430px);
			}
		}

		.headerContainer {
			img {
				max-height: 100%;
				max-width: 80%;
			}
			border-bottom: 1px solid $menu-bordercolor;
			padding: 10px 10px 10px 24px;
			.headerInnerContainer {
				justify-content: center;
				width: 100%;
				h1 {
					padding: 0;
				}
			}
			.menuButton {
				position: absolute;
				right: 31px;
				top: 37px;
			}
		}

		h1,
		.menuButton i {
			color: $text-default-color;
		}

		h1 {
			font-size: 18px;
			font-weight: 600;
			align-self: center;
		}
		.menuLogo {
			display: none; // Warten auf Konzept
			padding-left: 33px;
			position: absolute;
			bottom: 35px;
		}
	}

	.logo {
		position: fixed;
		display: flex;
		justify-content: center;
		bottom: 15px;
		left: 0;
		right: 0;
	}

	:global {
		.ms-TextField-errorMessage {
			background-color: rgba(255, 255, 255, 0.64);
			border-radius: 4px;
			display: block;
			margin-top: 5px;
			padding: 12px 16px;
			span {
				color: $error-textcolor;
				font-size: 14px;
				line-height: 20px;
			}
		}
	}
}

@keyframes startpageHeaderFadeIn {
	0% {
		transform: translateY(calc(50vh - 44px));
		transform: translateY(calc((var(--vh, 1vh) * 50) - 44px));
	}
	100% {
		transform: translateY(0px);
	}
}

@keyframes startpageHeaderFadeOut {
	0% {
		transform: translateY(0px);
	}
	80% {
		transform: translateY(0px);
	}
	100% {
		transform: translateY(-88px);
	}
}

@keyframes startpageContentFadeIn {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes startpageContentFadeOut {
	0% {
		height: calc(100vh - 88px);
		height: calc((var(--vh, 1vh) * 100) - 88px);
		max-height: calc(100vh - 88px);
		max-height: calc((var(--vh, 1vh) * 100) - 88px);
		overflow: hidden;
	}
	100% {
		height: 0px;
		max-height: 0px;
		overflow: hidden;
	}
}

@keyframes pinHeaderFadeIn {
	0% {
		height: 100vh;
		height: calc(var(--vh, 1vh) * 100);
	}
	100% {
		height: 48px;
	}
}
