$blue_0f: #0f7681;
$blue_18: #1893a0;
$blue_5c: #5cd7e4;
$blue_00: #00c1d5;
$black_43: #434343;
$black_33: #333333;
$grey_00: #00000029;
$grey_a0: #a0a0a0;
$grey_c2: #c2c2c2;
$grey_cf: #cfcfcf;
$grey_433D: #4343433d;
$grey_f0: #f0f0f0;
$grey_fb: #fbfbfb;
$grey_ef: #efefef;
$grey_f3: #f3f2f1;
$grey_f7: #f7f7f7;
$grey_72: #727272;
$grey_85: #858585;
$grey_d1: #d1d1d1;
$grey_95: #95989a;
$white: #ffffff;
$red_e1: #e15353;
$red_c7: #c72626;
$yellow_b1: #b18800;
$yellow_ff: #ffe695;
$yellow_61: #613e06;
$yellow_f0: #f0c072;

$neutralDark: #00274e;
$result_purple: #374a67;
$result_red: #e84855;

$primary-color: $blue_5c;
$text-special-color: $grey_c2;
$text-default-color: $black_43;
$button-special-backgroundcolor: $blue_18;
$button-special-text-color: $white;
$button-disabled-backgroundcolor: $grey_f7;
$button-disabled-text-color: $grey_c2;
$backgroundcolor: $grey_f0;
$selected-choice-bordercolor: $blue_0f;
$selected-disabled-choice-backgroundcolor: $grey_85;
$selected-disabled-choice-icon-backgroundcolor: $grey_85;
$selected-disabled-choice-icon-bordercolor: $grey_85;
$startpage-initial-backgroundcolor: $grey_ef;
$voting-bordercolor: $grey_f0;
$desktop-backgroundcolor: $blue_18;
$checkbox-bordercolor: $grey_95;
$primary-base-color: $blue_00;
$input-border-bottom-color: $blue_5c;
$command-button-textcolor: $blue_18;
$menu-bordercolor: $grey_d1;
$error-bordercolor: $red_e1;
$error-textcolor: $red_c7;
$choice-bordercolor: $grey_85;
$badge-background-color: #bce4e8;

$warning-bg-color: $yellow_ff;
$warning-border-color: $yellow_f0;
$warning-icon-color: $yellow_61;

@mixin for-desktop {
	@media (min-width: 1024px) {
		@content;
	}
}

@mixin for-phone {
	@media (max-width: 1024px) {
		@content;
	}
}
