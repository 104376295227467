@import "../../variables.scss";

.star {
    color: $blue_18;
    font-size: 20px;
    padding-right: 8px;
}

.resultAnswerContainer {
    display: flex;
    margin-top: 8px;
    h3 {
        color: $grey_72;
        margin-left: 12px;
        font-size: 16px;
        font-weight: 600;
        line-height: 19px;
        display: block;
        min-height: 38px;
    }
}

.answerTitle {
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: $grey_72;
}

.ratingEmpty {
    display: inline-block;
    position: relative;
}

.ratingFill {
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    overflow: hidden;
}