@import '../../variables.scss';

.ballotCounterContainer {
	align-items: center;
	background-color: $white;
	border-top: 1px solid $grey_00;
	border-bottom: 1px solid $grey_00;
	display: flex;
	height: 62px;
	justify-content: space-around;
	@include for-phone {
		justify-content: space-between;
	}
}

.leftCounter,
.rightCounter {
	color: $blue_18;
	font-size: x-large;
	width: 60px;
	&:hover {
		cursor: pointer;
		color: $blue_18;
		background-color: transparent;
	}
	&.disabled {
		color: $grey_c2;
		cursor: not-allowed;
	}
}

.leftCounter {
	text-align: right;
}

.counterInvisible {
	visibility: hidden;
}