@import '../../variables.scss';

.checkboxWithCounterContainer {
	align-items: center;
	background-color: $white;
	box-shadow: 0px 2px 4px #00000029;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	margin-bottom: 2px;
	position: relative;
	padding: 0;
	align-items: stretch;

	.answerContainer {
		background-color: $white;
		border: 2px solid $white;
		border-radius: 0;
		box-sizing: border-box;
		display: block;
		font-size: 14px;
		padding: 12px 16px;
		min-height: 48px;
		width: 100%;
	}
	.counter {
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 10px;
		color: $black_43;
		font-size: 12px;
		font-weight: 600;
		min-width: 30px;
		&.disabled {
			color: $grey_a0;
		}
	}
	.counterButton {
		background-color: $grey_f7;
		color: $blue_0f;
		border: solid 1px $grey_f0;
		border-width: 0 1px 0 1px;
		height: auto;
		min-width: 48px;
		flex-basis: 48px;
		padding: 0;
		border-radius: 0;
		font-size: 24px;

		&:hover {
			background-color: $grey_f0;
		}
		&:global(.is-disabled) {
			background-color: $grey_f0;
			color: $grey_c2;
		}
		:global(.ms-Button-flexContainer) {
			margin-bottom: 4px;
		}
		:global(.ms-Button-label) {
			font-weight: 600;
		}
		:global(.ms-Button-icon) {
			font-size: 11px;
			font-weight: bold;
		}
	}

	:global {
		.ms-Checkbox {
			.ms-Checkbox-checkbox {
				border: 2px solid $choice-bordercolor;
			}
			&.is-checked {
				.ms-Checkbox-checkbox {
					background-color: $selected-choice-bordercolor;
					border-color: $selected-choice-bordercolor;
					i {
						display: block;
					}
				}
			}
			&.is-disabled {
				.ms-Checkbox-checkbox {
					background-color: $backgroundcolor;
					border-color: $button-disabled-text-color;
					i {
						color: $button-disabled-text-color;
					}
				}
			}
			@include for-phone {
				&:not(.is-checked):hover .ms-Checkbox-checkbox i {
					display: none;
				}
			}
		}
	}
}
