@import '../variables.scss';

.menuList {
    list-style: none;
    margin: 0;
    padding: 26px 24px;

    .menuListItem {
        cursor: pointer;
        margin-bottom: 24px;

        i {
            font-size: 22px;
            padding-right: 22px;
        }
    }

    .menuListItem,
    .menuListItem a {
        align-items: center;
        color: $text-default-color;
        font-size: 18px;
        font-weight: 600;
        display: flex;
        justify-content: flex-start;
        text-decoration: none;
    }
}
