@import '../variables.scss';

.loginContainer {
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-bottom: 50px;
	> * {
		//margin-bottom: 20px;
		width: 320px;

		@media (max-width: 360px) {
			padding: 0 20px;
			width: 100%;
		}
	}

	&.specialContainer {
		label {
			color: $text-default-color;
		}
	}

	label {
		color: $white;
		font-size: 16px;
		font-weight: normal;
		line-height: 20px;
		padding-bottom: 12px;
		text-align: left;
	}

	input,
	:global(.ms-TextField-fieldGroup) {
		border: 0;
		border-bottom: 4px solid $white;
		border-radius: 4px;
		box-shadow: 0px 2px 4px #00000029;
		font-size: 16px;
		height: 53px;
		line-height: 53px;
		outline: 0;
		text-align: center;
		&::after {
			display: none;
		}
		&:focus {
			border-bottom: 4px solid $input-border-bottom-color;
		}
	}
	.error {
		margin-bottom: -30px;
		input,
		:global(.ms-TextField-fieldGroup) {
			border-bottom: 4px solid $error-bordercolor;
		}
	}
	/* Chrome, Safari, Edge, Opera */
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}

	/* Firefox */
	input[type='number'] {
		-moz-appearance: textfield;
	}

	button,
	button:hover,
	.loadingButton {
		background-color: $button-special-backgroundcolor;
		box-shadow: 0px 1px 2px #00000052;
		border-radius: 4px;
		border: 0;
		color: $button-special-text-color;
		height: 53px;
		font-size: 16px;
		line-height: 53px;
		margin-top: 48px;
		text-transform: uppercase;
		width: 248px;

		&:global(.is-disabled) {
			background-color: $button-disabled-backgroundcolor;
			box-shadow: 0px 2px 2px #00000029;
			color: $button-disabled-text-color;
		}
	}

	button {
		animation-name: fadeInButton;
		animation-duration: 1s;
	}

	.loadingButton {
		box-sizing: border-box;
		color: $button-special-text-color;
		display: flex;
		font-size: 16px;
		font-weight: 600;
		line-height: 53px;
		justify-content: center;
		text-align: center;
		text-transform: uppercase;
	}

	.emptyButton {
		height: 53px;
		margin-top: 48px;
		width: 248px;
	}
}

@keyframes fadeInButton {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
