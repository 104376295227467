@import '../variables.scss';

.votingContainer {
	.votingContentContainer,
	.votingContentContainerNoVote {
		background-color: $backgroundcolor;
	}

	.votingHeader,
	.votingContentContainerNoVote {
		background-color: $white;
	}

	.multipleQuestionsToolbar {
		@keyframes placeholderShimmer {
			0% {
				background-position: -150vw 0;
			}

			100% {
				background-position: 150vw 0;
			}
		}

		background-image: linear-gradient(135deg, $red_e1 35%, $red_c7 50%, $red_e1 55%, $red_c7 60%, $red_e1 75%, $red_e1);
		background-size: 300vw;

		animation-duration: 6s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeholderShimmer;
		animation-timing-function: linear;

		background-color: $red_e1;
		color: $white;
		font-weight: 400;

		@include for-desktop {
			background-image: linear-gradient(
				135deg,
				$red_e1 44%,
				$red_c7 52%,
				$red_e1 55%,
				$red_c7 58%,
				$red_e1 66%,
				$red_e1
			);
			animation-duration: 12s;
		}

		.marginContainerInner {
			padding: 11px 16px;
			@include for-desktop {
				padding-right: 0;
			}
		}

		.questionToolbarInner {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		.runningQuestionsCircle {
			background-color: $white;
			border-radius: 12px;
			color: $red_e1;
			display: inline-flex;
			font-weight: 600;
			line-height: 21px;
			height: 21px;
			padding: 1px 7px;
			text-align: center;
		}

		.runningQuestionsLabel {
			display: inline-flex;
			margin: 0 7px;
			width: calc(100% - 100px);
		}

		.switchButton {
			background-color: $white;
			border-color: $white;
			border-radius: 4px;
			color: $red_e1;
			float: right;
			font-size: 16px;
			height: 28px;
			padding: 0 20px;
			width: auto;

			:global(.ms-Button-label) {
				margin-bottom: 2px;
			}
		}
	}

	.questionSelector {
		background-color: $white;
		color: $text-default-color;
		overflow-y: auto;
		overflow-x: hidden;
		height: calc(100vh - 88px);
		height: calc((var(--vh, 1vh) * 100) - 88px);
		right: 0;
		top: 88px;
		bottom: 0;
		left: 100vw;
		width: 100vw;
		z-index: 10;

		&[class~='sidePanelOpen-enter'],
		&[class~='sidePanelOpen-exit'] {
			transition: left 0.25s linear;
		}

		@include for-desktop {
			width: 430px;
		}

		&.open {
			left: 0;

			@include for-desktop {
				left: calc(100vw - 430px);
			}
		}

		.questionSelectorOptionContainer {
			&:not(:last-child) {
				border-bottom: solid 1px $grey_d1;
			}
			cursor: pointer;
			width: 100vw;
			@include for-desktop {
				width: 100%;
			}

			.questionSelectorOption {
				display: flex;
				justify-content: space-between;
				min-height: 68px;
			}

			.status {
				align-items: center;
				display: inline-flex;
				font-size: 24px;
				padding: 0px 10px 0 32px;
				color: $grey_85;
				&.answered {
					color: $blue_0f;
				}
			}

			.title {
				align-items: center;
				display: inline-flex;
				font-weight: 300;
				width: 100%;
				overflow: hidden;

				span {
					display: -webkit-box;
					line-height: 21px;
					max-height: 42px;
					overflow: hidden;
					text-overflow: ellipsis;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					word-break: break-word;
				}
			}

			.switcher {
				align-items: center;
				display: inline-flex;
				min-width: 68px;

				> * {
					border: 1px solid $grey_85;
					border-radius: 4px;
					color: $grey_85;
					margin: 0 10px;
					padding: 7px 17px;
				}
			}

			&.active {
				.title {
					font-weight: 600;
				}
			}
		}
	}

	.votingContentContainer {
		min-height: calc(100vh - 88px - 15px);
		min-height: calc((var(--vh, 1vh) * 100) - 88px - 15px);
		width: 100%;
		padding-bottom: 15px;
	}

	.votingQuestion {
		font-size: 20px;
		padding-top: 6px;
		margin-left: -1px;

		display: -webkit-box;
		overflow: hidden;
		text-overflow: ellipsis;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
		word-break: break-word;
	}
	.votingDescription {
		color: $black_43;
		font-size: 16px;
		padding-top: 16px;
		display: inline-block;

		p {
			margin: 0;
			min-height: 21px;
		}
		a {
			color: $blue_0f;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.marginContainer {
		@include for-desktop {
			margin: auto;
			width: 1008px;
		}
	}
	.marginContainerInner {
		padding: 32px 16px;

		.badge {
			margin-top: 12px;
		}
	}
	.flexContainer {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		& > * {
			margin-bottom: 10px;
		}
	}
	.votingResultContainer {
		margin: 0 auto 32px;
		max-width: 1008px;
	}
	.resultHeader {
		color: $black_43;
		font-size: 16px;
		font-weight: 600;
	}

	.textResultContainer {
		column-count: 1;
		column-gap: 15px;
		padding: 20px 0;
		width: 100%;
		.textResultOuter {
			width: 100%;
		}
		.textResult {
			display: inline-block;
			width: calc(100% - 4px);
			background: $white;
			border: 2px solid $blue_18;
			border-radius: 7px;
			margin-top: 14px;
			line-height: 26px;
		}
		.textResultInner {
			overflow-wrap: break-word;
			padding: 10px 12px;
		}
	}
}
