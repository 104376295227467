@import '../../variables.scss';

.personLegendContainer {
	color: $grey_72;
	display: grid;
	font-size: 12px;
	font-weight: 600;
	grid-template-columns: 1fr 48px 56px 85px;
	justify-content: space-around;
	margin: 8px 12px;
	:first-child {
		padding-right: 8px;
	}
	span:first-child {
		font-weight: bold;
	}
	.personLabel {
		color: $black_43;
		font-size: 12px;
		font-weight: 600;
		letter-spacing: 0px;
	}
}
